import { departments } from '~~/constants/staff';

type UserStaffDeparmentId = { staff?: { department: { id: number } } };

export const isBoardOfDirectors = definePolicy(
	() => (user: UserStaffDeparmentId) => user.staff?.department.id === departments.BOARD_OF_DIRECTORS,
);

export const isExecutiveCouncil = definePolicy(
	() => (user: UserStaffDeparmentId) => user.staff?.department.id === departments.EXECUTIVE_COUNCIL,
);

export const isRegionRep = definePolicy(
	() => (user: UserStaffDeparmentId) => user.staff?.department.id === departments.REGIONAL_REPRESENTATIVE,
);

export const isCoordinator = definePolicy(
	() => (user: UserStaffDeparmentId) => user.staff?.department.id === departments.COORDINATOR,
);

export const isStaff = definePolicy(() =>
	some(isBoardOfDirectors(), isExecutiveCouncil(), isRegionRep(), isCoordinator()),
);
