export const isMemberType = definePolicy(
	(type: string) => (user: { member?: { membership_type: { type: string } } }) =>
		user.member?.membership_type.type === type,
);

export const isInChapter = definePolicy(
	(chapter_id?: string) => (user: { member: { affiliation: string } }) =>
		user?.member?.affiliation === chapter_id && !!chapter_id,
);

export const isInAChapter = definePolicy(
	() => (user: { member: { affiliation: string } }) => user?.member?.affiliation !== null,
);

export const isAdvisor = definePolicy(() => all(isMemberType('advisor'), isInAChapter()));

export const isStudent = definePolicy(() => isMemberType('student'));
