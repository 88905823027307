export default defineNuxtRouteMiddleware((to) => {
	const { loggedIn, user } = useUserSession();

	if (!loggedIn.value || !user.value || !user.value?.role) {
		// check that to is a relative path to avoid redirecting to external URLs, be strict about it
		if (to.path.startsWith('http') || to.path.startsWith('//') || to.path.startsWith('mailto:')) {
			return navigateTo('/login');
		}

		return navigateTo(`/login?redirect=${to.path}`);
	}

	if (isPendingEmailVerification()(user.value)) {
		return navigateTo('/verify');
	}

	if (!isAppUser()(user.value)) {
		return showError(createError({
			statusCode: 403,
			statusMessage: 'Forbidden: App Access Required',
			message: 'You must be an app user to access this page.',
			fatal: true,
		}));
	}

	if (to.path === '/verify' && !isPendingEmailVerification()(user.value)) {
		return navigateTo('/');
	}

	if (to.path.startsWith('/advisor') && !isMemberType('advisor')(user.value)) {
		return showError(createError({
			statusCode: 403,
			statusMessage: 'Forbidden: Advisors Only',
			message: 'You must be an advisor to access this page.',
			fatal: true,
		}));
	}

	if (to.path.startsWith('/staff') && !user.value.staff) {
		return showError(createError({
			statusCode: 403,
			statusMessage: 'Forbidden: Staff Only',
			message: 'You must be a staff member to access this page.',
			fatal: true,
		}));
	}

	if (to.path === '/') {
		if (!user.value.member && user.value.staff) {
			navigateTo('/staff');
		}

		if (user.value.member && !user.value.staff) {
			navigateTo('/advisor');
		}
	}
});
